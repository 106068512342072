<template>
  <div>
    <QueryForm v-model="queryForm" :query-btn-loading="tableLoading" @query="handleDefaultQuery" @reset="handleQueryFormReset">
      <el-form-item label="数据筛选维度">
        <el-radio-group v-model="dataQueryType" @change="handleTabClick">
          <el-radio label="style">Style</el-radio>
          <el-radio label="sku">Sku</el-radio>
        </el-radio-group>
      </el-form-item>
      <br>
      <el-form-item label="采购计划单号">
        <el-input v-model="queryForm.purchasePlanCode" :placeholder="$t('page.inputPlaceholder')" />
      </el-form-item>
      <el-form-item label="创建人">
        <el-input v-model="queryForm.createByName" :placeholder="$t('page.inputPlaceholder')" />
      </el-form-item>
      <el-form-item label="BU">
        <CommonSelect
          v-model="queryForm.buNameList"
          api-key="buList"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="状态">
        <CommonSelect
          v-model="queryForm.orderStatusList"
          clearable
          multiple
          :select-options="selectOptions"
          :configuration="optionsConfig"
        />
      </el-form-item>
      <el-form-item label="平台">
        <CommonSelect
          v-model="queryForm.sellPlatformId"
          api-key="platformList"
          clearable
          :configuration="{ key: 'id', label: 'platformName', value: 'id'}"
          @responseData="val=>platformList=val"
          @change="queryForm.siteCode=''"
        />
      </el-form-item>
      <el-form-item label="站点">
        <CommonSelect
          v-model="queryForm.siteCode"
          clearable
          ganged
          :params="{sellPlatformId:queryForm.sellPlatformId}"
          api-key="siteList"
          :not-required-params="true"
        />
      </el-form-item>
      <el-form-item label="Style">
        <CommonSelect
          v-model="queryForm.styleNameList"
          api-key="styleList"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="SKU">
        <el-input
          v-model="queryForm.skuList"
          placeholder="多个SKU以换行符分隔"
          type="textarea"
          :disabled="dataQueryType==='style'"
          :rows="2"
        />
      </el-form-item>
      <el-form-item label="季节">
        <CommonSelect
          v-model="queryForm.season"
          :select-options=" _getAllCommodityDict('SEASON')"
          :configuration="optionsConfig"
        />
      </el-form-item>
      <el-form-item label="时间选择">
        <el-date-picker
          v-model="queryForm.value"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
    </QueryForm>
    <el-row :span="24" class="mb-3">
      <el-col :span="24">
        <ImportButton export-key="purchase_plan_report" :params="exportParameter" :btn-type="'primary'" />
      </el-col>
    </el-row>
    <Table
      :table-data="tableData"
      :max-height="'600px'"
      :columns="purchasePlanTableColumns"
      :check="true"
      :loading="tableLoading"
      :page-obj="pager"
      @handleSelectionChange="val => {multipleSelectionTable=val}"
    >
      <el-table-column v-if="dataQueryType==='sku'" slot="sku" label="SKU" align="left" min-width="180">
        <template slot-scope="scope">{{ scope.row.sku }}</template>
      </el-table-column>
      <el-table-column v-if="dataQueryType==='sku'" slot="color" label="Color" min-width="100">
        <template slot-scope="scope">{{ scope.row.color }}</template>
      </el-table-column>
      <el-table-column v-if="dataQueryType==='sku'" slot="size" label="Size" width="60">
        <template slot-scope="scope">{{ scope.row.size }}</template>
      </el-table-column>

    </Table>
    <Paging :pager="pager" end @pagination="pagerUpdate" />
  </div>
</template>

<script>
import QueryForm from '@/components/QueryForm'
import CommonSelect from '@/components/CommonSelect'
import Paging from '@/components/Pagination'
import { purchasePlanTableColumns } from '@/constant/tablecolumns'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { getPurchasePlanReportByPage, getPurchasePlanDetailReportByPage } from '@/api/scm-api'
import { omit } from 'lodash'
import Table from '@/components/Table'
import ImportButton from '@/components/ExportFile'

export default {
  components: { QueryForm, CommonSelect, Paging, Table, ImportButton },
  mixins: [commodityInfoDict],

  data() {
    return {
      purchasePlanTableColumns,
      tableData: [],
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      queryForm: { value: [], siteCode: '', skuList: '', season: '' },
      dataQueryType: 'style',
      platformList: [],
      tableLoading: false,
      optionsConfig: { key: 'val', label: 'label', value: 'val' },
      multipleSelectionTable: [],
      selectOptions: [{ label: '草稿', val: 0 }, { label: '审批中', val: 1 }, { label: '审批通过', val: 2 }, { label: '部分转订单', val: 3 },
        { label: '已完成', val: 4 },
        { label: '审批拒绝', val: 5 },
        { label: '已作废', val: 9 }]

    }
  },
  computed: {
    queryParams() {
      const { value = [], sellPlatformId, skuList = '' } = this.queryForm
      const list = skuList.replaceAll('\n', ',')
      const platformName = this.platformList.find(item => item.id === sellPlatformId)?.platformName
      const [createBeginDate, createEndDate] = value || []
      return Object.assign({}, { dataQueryType: this.dataQueryType }, omit(this.queryForm, ['value', 'sellPlatformId']), { createBeginDate, createEndDate, platformName }, this.pager,
        { skuList: list && list.split(',').filter(item => item) || [] })
    },
    exportParameter() {
      const idsList = []
      this.multipleSelectionTable.map(item => idsList.push(item.id))
      return Object.assign({}, this.queryParams, { idsList })
    }
  },
  mounted() {
    this.handleDefaultQuery()
  },
  methods: {
    handleTabClick(val) {
      this.queryForm.skuList = ''
      this.handleQuery()
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.handleQuery()
    },
    handleDefaultQuery() {
      this.pager.current = 1
      this.handleQuery()
    },
    async handleQuery() {
      try {
        this.tableLoading = true
        if (this.dataQueryType === 'style') {
          const { datas: { pager, records }} = await getPurchasePlanReportByPage(this.queryParams)
          this.pager = pager
          this.tableData = records
        } else {
          const { datas: { pager, records }} = await getPurchasePlanDetailReportByPage(this.queryParams)
          this.pager = pager
          this.tableData = records
        }
      } finally {
        this.tableLoading = false
      }
    },
    pagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    }
  }
}
</script>

<style>

</style>
